import { useLocation } from "@reach/router";
import { motion } from "framer-motion";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { Col, Row } from "react-flexbox-grid";

import { SearchIcon } from "../../components/Icons";
import * as styles from "./styles.module.css";

const Menu = () => {
  const data = useStaticQuery(graphql`
    {
      home: file(relativePath: { eq: "hero/cwrusign.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      about: file(relativePath: { eq: "hero/about.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            aspectRatio: 1.0
          )
        }
      }
      academics: file(relativePath: { eq: "hero/academics.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      admissions: file(relativePath: { eq: "hero/admission.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            aspectRatio: 2.0
            transformOptions: { cropFocus: EAST }
          )
        }
      }
      campusLife: file(relativePath: { eq: "hero/campus-life.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            aspectRatio: 2.0
            transformOptions: { cropFocus: WEST }
          )
        }
      }
      research: file(relativePath: { eq: "hero/research.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            aspectRatio: 2.0
            transformOptions: { cropFocus: WEST }
          )
        }
      }
      giving: file(relativePath: { eq: "hero/giving.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: FULL_WIDTH
            transformOptions: { cropFocus: NORTH }
          )
        }
      }
    }
  `);

  const menuItems = [
    { name: "About", to: "/about", src: data.about, alt: "hero" },
    { name: "Academics", to: "/academics", src: data.academics, alt: "hero" },
    {
      name: "Admissions",
      to: "/admissions",
      src: data.admissions,
      alt: "hero",
    },
    {
      name: "Campus Life",
      to: "/campus-life",
      src: data.campusLife,
      alt: "hero",
    },
    { name: "Research", to: "/cwruresearch", src: data.research, alt: "hero" },
    { name: "Giving", to: "/giving", src: data.giving, alt: "hero" },
  ];

  const resourceItems = [
    { name: "Students", to: "/students" },
    { name: "Faculty", to: "/faculty" },
    { name: "Staff", to: "/staff" },
    { name: "Alumni", to: "/alumni-resources" },
    { name: "Parents and Families", to: "/parents-families" },
    { name: "Community", to: "/community" },
  ];

  const quickItems = [
    { name: "Directory", to: "https://webapps.case.edu/directory/" },
    { name: "Webmail", to: "https://mail.google.com/a/case.edu/" },
    { name: "Canvas", to: "https://canvas.case.edu/" },
    { name: "Maps", to: "https://webapps.case.edu/map/" },
    { name: "UTech", to: "https://case.edu/utech/" },
  ];

  // initializes image on left of menu to match current path
  // falls back to the data.home img if there's no exact match
  // TODO: figure out how to match first part of path for nested pages
  let initialImg;
  let location = useLocation();
  for (let i = 0; i < menuItems.length; i++) {
    if (location.pathname === menuItems[i].to) {
      initialImg = menuItems[i].src;
      break;
    } else {
      initialImg = data.home;
    }
  }

  const [imgSrc, setImgSrc] = useState(getImage(initialImg));

  return (
    <motion.div
      initial="initial"
      animate="animate"
      className={styles.container}
    >
      <motion.div
        className={styles.panel}
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.3 }}
      ></motion.div>
      <motion.div
        className={`${styles.panel} ${styles.right}`}
        initial={{ y: "-100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.3 }}
      ></motion.div>
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.75 }}
        className={styles.leftHalf}
      >
        <GatsbyImage className={styles.imageHolder} image={imgSrc} alt="hero" />
      </motion.div>
      <motion.div
        initial={{ y: "-100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.75 }}
        className={styles.menuRight}
      >
        <form
          action="//www.case.edu/search-results/"
          role="search"
          aria-label="search"
        >
          <label htmlFor="q">
            Search:
            <input
              type="search"
              placeholder="What are you looking for?"
              id="site-search"
              name="q"
            />
            <input
              type="hidden"
              name="cx"
              value="004305171799132815236:ciq4c8b3yv4"
            />
            <input type="hidden" name="ie" value="UTF-8" />
            <input
              style={{ display: "none" }}
              name="sa"
              type="submit"
              title="search"
              alt="search"
              value="search"
            ></input>
            <button type="submit" className={styles.icon} aria-label="Search">
              <SearchIcon color="#fff" />
            </button>
          </label>
        </form>
        <div className={styles.menuHeight}>
          <div>
            <Row middle="xs" between="md">
              <Col xs={12} md={6}>
                <motion.ul
                  className={styles.menuMain}
                  variants={staggerUp}
                  id="main-menu"
                >
                  {menuItems.map((menuItem) => (
                    <motion.li
                      key={menuItem.name}
                      variants={staggerUpChild}
                      whileHover={{ x: 10 }}
                      onMouseOver={() => setImgSrc(getImage(menuItem.src))}
                    >
                      <span></span>
                      <Link
                        to={menuItem.to}
                        activeStyle={{ color: "var(--orange)" }}
                        partiallyActive={true}
                      >
                        {menuItem.name}
                      </Link>
                    </motion.li>
                  ))}
                </motion.ul>
              </Col>
              <Col xs={12} md={5}>
                <div className={styles.forLinks}>
                  <label htmlFor="for-link-ul">Information For:</label>
                  <motion.ul variants={staggerUp} id="for-link-ul">
                    {resourceItems.map((resourceItem) => (
                      <motion.li
                        key={resourceItem.name}
                        variants={staggerUpChild}
                      >
                        <Link to={resourceItem.to}>{resourceItem.name}</Link>
                      </motion.li>
                    ))}
                  </motion.ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className={styles.otherLinks}>
            <motion.ul variants={staggerUp} id="other-link-ul">
              <label htmlFor="other-link-ul">Quick Links:</label>
              {quickItems.map((quickItem) => (
                <motion.li key={quickItem.name} variants={staggerUpChild}>
                  <a href={quickItem.to}>{quickItem.name}</a>
                </motion.li>
              ))}
            </motion.ul>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};
export const staggerUp = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.04,
    },
  },
};

export const staggerUpChild = {
  initial: {
    y: 100,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.3 },
  },
};

export default Menu;
