import React from "react";

export const ArrowIcon = ({ color = "#ff9239", direction = "right" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.141"
    height="20.141"
    viewBox="0 0 448 512"
    style={{ display: "inline-block", verticalAlign: "middle" }}
  >
    <path
      d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
      fill={color}
      transform-origin="center"
      transform={direction === "right" ? "rotate(0)" : "rotate(180)"}
    />
  </svg>
);

export const SearchIcon = ({ color = "#000" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.178"
    height="21.205"
    viewBox="0 0 20.178 21.205"
  >
    <g id="Search" transform="translate(0.709 1)">
      <path
        id="Path_1"
        data-name="Path 1"
        d="M10.362,2.5A7.862,7.862,0,1,1,2.5,10.362,7.862,7.862,0,0,1,10.362,2.5Z"
        transform="translate(0.245 -2.5)"
        fill="none"
        stroke={color}
        stroke-width="2"
      />
      <line
        id="Line_5"
        data-name="Line 5"
        y1="5.524"
        x2="5.493"
        transform="translate(0 13.976)"
        fill="none"
        stroke={color}
        stroke-width="2"
      />
      <path
        id="Path_1-2"
        data-name="Path 1"
        d="M10.362,2.5A7.862,7.862,0,1,1,2.5,10.362,7.862,7.862,0,0,1,10.362,2.5Z"
        transform="translate(0.245 -2.5)"
        fill="none"
        stroke={color}
        stroke-width="2"
      />
      <line
        id="Line_5-2"
        data-name="Line 5"
        y1="5.524"
        x2="5.493"
        transform="translate(0 13.976)"
        fill="none"
        stroke={color}
        stroke-width="2"
      />
    </g>
  </svg>
);
