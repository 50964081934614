import { globalHistory } from "@reach/router";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import socialLogo from "../images/cwru-social-logo.jpg";
import favicon from "../images/favicon.ico";
import "./globals.css";

const Layout = ({ children }) => {
  // styles
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  };
  const contentStyle = {
    flex: "1 0 auto",
  };

  // global menu state for passing to Navbar component
  const [active, setActive] = useState(false);
  const clickHandler = () => {
    setActive(!active);
  };

  // set menu's active state to false on any page navigation
  useEffect(() => {
    var referrerLocation = window.location.href;
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
    }
    setTimeout(() => {
      window.dataLayer.push({
        event: "top-level-route-change",
        page: {
          title: document.title,
          path: window.location.href,
        }, 
        site_name: "top level"
      });
    }, 500);
    return globalHistory.listen(() => {
      if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || [];
      }
      setTimeout(() => {
        window.dataLayer.push({
          event: "top-level-route-change",
          page: {
            title: document.title,
            path: window.location.href,
            referrer: referrerLocation,
          }, 
          site_name: "top level"
        });
        referrerLocation = window.location.href;
      }, 500);
      setActive(false);
    });
  }, [setActive]);

  return (
    <>
      <Helmet
        bodyAttributes={{ class: active ? "no-scroll" : null }}
        htmlAttributes={{ lang: "en" }}
      >
        <link rel="icon" href={favicon} />
        <meta name="referrer" content="strict-origin-when-cross-origin" />
        <meta
          property="og:site_name"
          content="Case Western Reserve University"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://case.edu/" />
        <meta property="og:title" content="Case Western Reserve University" />
        <meta property="og:image" content={socialLogo} />
        <meta property="og:image:type" content="&#039;image/jpeg&#039;" />
        <meta property="og:image:width" content="265" />
        <meta property="og:image:height" content="265" />
        <meta property="place:location:latitude" content="41.504341" />
        <meta property="place:location:longitude" content="-81.608384" />
        <meta property="og:street_address" content="10900 Euclid Ave." />
        <meta property="og:locality" content="Cleveland" />
        <meta property="og:region" content="Ohio" />
        <meta property="og:postal_code" content="44106" />
        <meta property="og:country_name" content="United States" />
        <meta property="og:email" content="marcominfo@case.edu" />
        <meta property="og:phone_number" content="216.368.4440" />
        <meta property="og:locale" content="en_US" />
      </Helmet>
      <div style={containerStyle}>
        <Navbar clickHandler={clickHandler} active={active} />
        <main id="main-content" style={contentStyle}>
          {children}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
