import React from "react";

const TwitterIcon = () => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="34"
  //   height="34"
  //   viewBox="0 0 24 24"
  // >
  //   <path
  //     fill="#fff"
  //     d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.5 8.778c-.441.196-.916.328-1.414.388.509-.305.898-.787 1.083-1.362-.476.282-1.003.487-1.564.597-.448-.479-1.089-.778-1.796-.778-1.59 0-2.758 1.483-2.399 3.023-2.045-.103-3.86-1.083-5.074-2.572-.645 1.106-.334 2.554.762 3.287-.403-.013-.782-.124-1.114-.308-.027 1.14.791 2.207 1.975 2.445-.346.094-.726.116-1.112.042.313.978 1.224 1.689 2.3 1.709-1.037.812-2.34 1.175-3.647 1.021 1.09.699 2.383 1.106 3.773 1.106 4.572 0 7.154-3.861 6.998-7.324.482-.346.899-.78 1.229-1.274z"
  //   />
  // </svg>

  <svg 
  xmlns="http://www.w3.org/2000/svg" 
    width="34"
    height="34" 
    viewBox="0 0 34 34">
    {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
    {/* <path 
      fill="#FFF"
      d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
      <path class="cls-1" d="m17,2.83c7.81,0,14.17,6.36,14.17,14.17s-6.36,14.17-14.17,14.17S2.83,24.81,2.83,17,9.19,2.83,17,2.83Zm0-2.83C7.61,0,0,7.61,0,17s7.61,17,17,17,17-7.61,17-17S26.39,0,17,0Z"/> */}
  <path fill="#FFF" class="cls-1" d="m17,2.83c7.81,0,14.17,6.36,14.17,14.17s-6.36,14.17-14.17,14.17S2.83,24.81,2.83,17,9.19,2.83,17,2.83Zm0-2.83C7.61,0,0,7.61,0,17s7.61,17,17,17,17-7.61,17-17S26.39,0,17,0Z"/>
  <path fill="#FFF" class="cls-1" d="m18.96,15.42l7.66-8.73h-1.81l-6.65,7.58-5.31-7.58h-6.13l8.03,11.47-8.03,9.16h1.81l7.02-8.01,5.61,8.01h6.13l-8.33-11.89h0Zm-2.49,2.84l-.81-1.14-6.47-9.09h2.79l5.22,7.33.81,1.14,6.79,9.53h-2.79l-5.54-7.78h0Z"/>
  </svg>
);

export default TwitterIcon;
