// extracted by mini-css-extract-plugin
export var active = "styles-module--active--8743f";
export var container = "styles-module--container--5e417";
export var forLinks = "styles-module--for-links--d88ab";
export var icon = "styles-module--icon--9a353";
export var imageHolder = "styles-module--image-holder--b1ab4";
export var leftHalf = "styles-module--left-half--cbaf5";
export var menuHeight = "styles-module--menu-height--8651c";
export var menuMain = "styles-module--menu-main--3c3d8";
export var menuRight = "styles-module--menu-right--9424f";
export var otherLinks = "styles-module--other-links--9dd76";
export var panel = "styles-module--panel--57228";
export var right = "styles-module--right--739eb";